.modal {
  display:flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
}
.modal-close-button{
  background-color: black;
  border-radius: 10px;
  color:white;
  padding:10px;
  margin:5px;
  margin-bottom:20px;
}
.modal-text{
  font-size: 20px;
  padding:30px;
}
.confirmation-message{
  white-space:pre-line;
}
.confirmation-buttons{
  padding:10px;

}
@media (max-width: 1000px) {
  .modal {
    padding: 20px 30px;
  }
}
