/* APP */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}
/* HOME */
.home {
  min-height: 80vh;
  background-image: url('../assets/mmp_background_image.jpg'); /* Replace 'path/to/your/image.jpg' with the path to your image */
  background-size: cover; /* Cover the entire background with the image */
  background-position: center; /* Center the background image */
  width:100%;
  display: flex; /* Add flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.home-content{
  display: flex;
  flex-direction: row;
  color:white;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.home-left-content {
  display: flex;
  flex-direction: column;
  flex:2;
  padding: 10px;
  align-items: center;
}
.home-left-text{
  flex:1;
  margin-bottom:5rem;
}
.home-quote-button {
  flex:1;
  color: white; /* Set the text color to white */
  display: block;
  border-radius: 10px;
  border: none; /* Remove the border */
  border-color: black;
  font-weight: 400;
  font-size: 20px;
  width: 20%;
  background-color: black;
  padding: 30px 30px;

}
.home-right-content {
  flex:1;
  padding:50px;
  background:black;
  margin:20px;

}
.home-subheading{
  color:white;
  text-align: center;
  padding-bottom: 20px;
  font-size: 25px;
}
.home-name{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.home-boxes{
  margin:5px;
}
.home-inputs{

}
.home-button{
  display: block;
  border-radius: 10px;
  font-weight: 500;
  width:80%;
  margin:auto;
  background-color: white;
  margin-top: 20px;
  padding: 5px;
}
/* ABOUT US */
.aboutus{
  margin-top:20px;
  width: 70%;
  position: relative;
  top:-6rem;
  background: black;
  padding-bottom: 4rem;
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right:1rem;
}
.aboutus-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:white;
}
.aboutus-logo{
  max-width: 100%;
  width: 15rem;
  padding-bottom:5px;
   filter: brightness(0) invert(1); /* Change the image to white */

}
.aboutus-text{
  text-align: center;
}
/* SERVICES */
.services{
  padding-top: 50px;
  width:70%;
}
.services-heading{
  text-align: center;
  padding-bottom: 20px;
}
.services-list{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);

}
.service-sections{
  padding:5px;
  text-align: center;
  margin:10px;
}
.service-card-class{
  margin:5px;
  height:320px;
}
.services-card-images{
  padding-bottom:10px;
}
.services-card-body{
  padding-bottom:10px;
}
/* REVIEWS */
.review{
  width: 100%;
  display:flex;
  flex-direction: column;
  background:black;
  align-items: center;
  padding-bottom:100px;
  margin-bottom: 50px;
  padding-top:50px;
  margin-top:10rem;
}
.review-heading{
  color:white;
  padding-bottom:50px;
}
.review-content{
  display: flex;

}
.review-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  font-size:20px;
  color: lightgrey;
}
.review-button span {
  font-size:1.5rem;
  color:white;
}
.reviewPic{
  max-width: 25rem;
  min-width: 25rem;
  max-height: 13rem;
  min-height: 13rem;
  border-radius:25px;
}
/* FAQ */
.faq{
  text-align: center;
  margin: 10px;
  width:80%;
  margin-bottom:20rem;
}
.faq-heading{
  padding-bottom: 50px;
  padding-top:50px;
}
.faq-list {
  max-width: 90%;
  padding-left:10px;
  display:grid;
  grid-template-columns: repeat(1, 1fr); /* Create a grid with 2 columns */
  gap: 20px; /* Add spacing between button blocks */
}
.faq-content{

}
.faq-text-body{
  text-align: left;
}

/* Customizing the scrollbar track */
textarea::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  background-color: white;
}

/* Customizing the scrollbar thumb */
textarea::-webkit-scrollbar-thumb {
  background-color: black; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners for the scrollbar thumb */
}

/* Customizing the scrollbar track when hovered */
textarea::-webkit-scrollbar-thumb:hover {
  background-color: #999; /* Color of the scrollbar thumb when hovered */
}
@media(max-width:1432px) {
  .home-quote-button {
    width: 25%;
  }
  .aboutus{
    width: 75%;
  }
}
@media(max-width:1356px) {
  .services{
    padding-top: 50px;
    width:80%;
  }
  .aboutus{
    width: 80%;
  }
}
@media(max-width:1186px) {
  .services{
    padding-top: 50px;
    width:90%;
  }
  .home-quote-button {
    width: 30%;
  }
  .aboutus{
    width: 85%;
  }

}
@media(max-width:1059px) {
  .services{
    padding-top: 50px;
    width:90%;
  }
  .services-list{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);

  }
  .services-card-body{
    font-size: 40px;
  }
  .home-quote-button {
    width: 35%;
  }
  .aboutus{
    width: 95%;
  }
}
@media(max-width:895px) {
  .home-quote-button {
    width: 43%;
  }
}
@media(max-width:768px) {
  /* APP */
    .App {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .app-section{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  /* HOME */
    .home {
      min-height: 80vh;
      background-image: url('../assets/mmp_background_image.jpg'); /* Replace 'path/to/your/image.jpg' with the path to your image */
      background-size: cover; /* Cover the entire background with the image */
      background-position: center; /* Center the background image */
      width:100%;
      display: flex; /* Add flexbox */
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
    }
    .home-content{
      display: flex;
      flex-direction: column;
      color:white;

    }
    .home-left-content {
      display: flex;
      width: 80%;
      flex:1;
      padding: 10px;
      margin-top: 30px;
    }
    .home-left-text{
      padding-top: 50px;

    }
    .home-quote-button {

    }
    .home-right-content {
      flex:1;
      padding:50px;
      background:black;
      margin:20px;

    }
    .home-subheading{
      color:white;
      text-align: center;
      padding-bottom: 20px;
      font-size: 25px;
    }
    .home-name{
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .home-boxes{
      margin:5px;
    }
    .home-inputs{
      background-color: lightgrey;
      border-color: lightgrey;
    }
    .home-button{
      display: block;
      border-radius: 10px;
      font-weight: 500;
      width:80%;
      margin:auto;
      background-color: white;
      margin-top: 20px;
      padding: 5px;
    }
  /* ABOUT US */
    .aboutus{
      margin-top:20px;
      width: 95%;
      position: relative;
      top:-2rem;
      background: #1A1A1A;
      padding-bottom: 4rem;
      border-radius: 1rem;
      padding-left: 0rem;
      padding-right:0rem;
    }
    .aboutus-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color:white;
      padding:10px;
    }
    .aboutus-logo{
      max-width: 100%;
      width: 15rem;
      padding-bottom:5px;
       filter: brightness(0) invert(1); /* Change the image to white */

    }
    .aboutus-text{
      text-align: center;
    }
  /* SERVICES */
    .services{
      padding-top: 50px;
      width:95%;
    }
    .services-heading{
      text-align: center;
      padding-bottom: 20px;
    }
    .services-list{
      width: 95%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
    }
    .service-sections{
      padding:5px;
      text-align: center;
      margin:0px;
    }
    .services-card-class{
      padding:5px;
    }
    .services-card-images{

    }

  /* REVIEWS */
    .review {

    }
    .review-heading{
      color:white;
      padding-bottom:30px;
    }
    .review-content{
      padding-top:0px;
      display: flex;
      flex-direction: row;

    }
    .review-button{
      display: none;
    }
    .reviewPic{
      padding:20px;
    }
  /* FAQ */
    .faq{
      margin: 10px;
      width:90%;
      padding-bottom:100px;
    }
    .faq-list{

    }
    .faq-content{

    }
    .faq-text-body{
      text-align:center;
    }
}
