/*Main form styling */
.Estimate {
  display: flex;
  flex-direction:column;
  align-items: center;
}
.heading {
  margin-top:50px;
}
.form {
  border: 2px solid #ccc; /* Border style, color, and width */
  padding: 20px; /* Add some padding to create space between the content and the border */
  border-radius: 5px; /* Rounded corners for the border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 50%;
  margin-top:30px;
}

/*Personal details */
.customer-name {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;

}
.customer-email {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.form-subheading {
  border-bottom: 1px inset #ddd; /* Adjust the border properties as needed */
  display: flex; /* Ensure the underline spans the entire width of the container */
  padding-bottom: 5px; /* Add some space between the text and the underline */
}
.input-boxes {
  margin:5px;
}

/*Address details*/
.address {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.address-subinfo{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.boxSupplyAmount {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.address-text-textbox{
  display: flex;
  flex-direction: column;
}
.item-list{
  display: flex;

}
.survey-group{
  display: flex;
  width:95%;
  margin: 0 auto; /* Add this line to center the container */
  padding: 20px; /* Add some padding to create space between the content and the border */
}
.room-picker-button-group {
  flex:1;
}
.item-picker-buttons {
  flex: 4;
  padding-left: 10px;
}
.item-desc{
  flex:6;
  padding: .75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-bottom-left-radius:5px;
  border-top-left-radius: 5px;
}
.item-desc.selected{
  background-color: #DFDDDD;
  color: black;
}
.qty-button {
  flex: 1;
  padding: .75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-bottom-right-radius:5px;
  border-top-right-radius: 5px;
}
.qty-button.selected {
  background-color: #DFDDDD;
  color: black;
}
.room-heading{
  padding-left: 10px;
}
.item-button {
  display: inline-block;
  width: 100%;
  padding: .75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  /* Add other styles like font-size, font-weight, etc. as needed */
}
.item-button:hover {
  background-color: #0056b3; /* Change background color on hover */
}
.item-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 2px #007BFF; /* Add a blue border when focused */
}
.item-button.selected {
  background-color: black;
  color: #fff;
}
.room-button{
  display: inline-block;
  width: 100%;
  padding: 2rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.room-button.selected {
  background-color: #DFDDDD;
  color: black;
}
.additional-item-dropdown{
  padding-top: 20px;
  padding-bottom: 20px;
}
.additional-item-list{
  padding-top: 10px;
  padding-left: 10px;
  display: grid;
  grid-template-columns: 1fr;
}
.submit-button-container{
  padding-top: 20px;
  padding-bottom: 20px;
}
.submit-button {
  display: block;
  margin: auto;
  background-color: black;
  border-radius: 10px;
  color: white;
  width: 30%;
  padding:5px;

}
.surveyBooking {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.note-area {
  margin-top:10px;
}
@media(max-width:1904px) {
  .form {
    width: 65%;
  }
}
@media (max-width:1592px) {
  .form {
    width: 70%;
  }
}
@media (max-width:1366px) {
  .form {
    width: 75%;
  }
}
@media (max-width:1278px) {
  .form {
    width: 80%;
  }
}
@media (max-width:1198px) {
  .form {
    width: 95%;
  }

}
@media (max-width:1015px) {
  .form {
    width: 95%;
  }
  .address {
    display: block;
  }
  .address textarea {
    width: 100%;
  }
  .survey-group{
    display: block;
    width:95%;
    margin: 0 auto; /* Add this line to center the container */
    padding: 0px; /* Add some padding to create space between the content and the border */

  }
  .boxSupplyAmount {
    grid-template-columns: 1fr 1fr;
  }
  .room-button{
    display: inline-block;
    width: 100%;
    padding: .1rem;
    font-size: .8rem;
    font-weight: normal;
  }
  .item-desc{
    flex:8;
    padding: .5rem;
    font-size: .8rem;
    font-weight: normal;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-bottom-left-radius:5px;
    border-top-left-radius: 5px;
  }
  .qty-button {
    flex: 1;
    padding: .5rem;
    font-size: .8rem;
    font-weight: normal;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
  }
  .room-picker-button {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    padding-bottom: 10px;
  }
  .room-picker-button-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-left: 0px;
    border: 0px solid #ccc; /* Border style, color, and width */

  }
  .item-picker{
    display: grid;
    grid-template-columns: 1fr;
    padding-top:20px;
    padding-left:10px;
    padding-right:10px;
  }
  .item-picker-buttons {
    flex: 4;
    padding-left: 0px;
  }
}
