.privacy-policy-content{
  padding-top:50px;
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
  margin: auto;
  padding-bottom:10rem;

}
.privacy-policy-heading{
  text-align: center;
  padding-bottom: 50px;
}
