.callbanner {
  margin-top:20px;
  background:black;
  width: 100%;
  margin-bottom:50px;
}
.callbanner-content{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  width: 60%;
  margin:auto;
  padding: 1rem;
}
.callbanner-text{
  color:white;
}

.callbanner-text {
  margin-top:20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border-color: white;
  font-size: 1.5rem;
  padding:10px;
  color:white;
}


.callbanner-button,
.callbanner-input-name,
.callbanner-input-phone{

  margin-top: 1rem;
  margin-bottom:1rem;
  border-radius: 5px;
  border-color: white;
  font-size: 1.5rem;
  color: black;
  background: white;
  border-color: black;
}
.callbanner-button{
  border-radius: 30px;
  padding-left:20px;
  padding-right:20px;
  font-size:1.2rem;
  font-weight: 500;
  justify-content: center;
}
.callbanner-input-name::placeholder,
.callbanner-input-phone::placeholder{
  font-size:1.2rem;
}
@media (max-width:1750px) {

  .callbanner-content{
    width:70%;
  }
}

@media (max-width:1480px) {
  .callbanner-content{
    width:80%;
  }
}
@media (max-width:1300px) {

  .callbanner-content{
    width:90%;
  }
}
@media (max-width:1170px) {

  .callbanner-content{
    width:95%;
  }
}
@media (max-width:1100px) {

  .callbanner-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 50px;
  }
  .callbanner-text{
    text-align: center;
  }
  .callbanner-button{

    width: 80%;
    margin: auto;
  }
  .callbanner-button,
  .callbanner-input-name,
  .callbanner-input-phone{
    padding:5px;
  }
  .callbanner-input-name::placeholder,
  .callbanner-input-phone::placeholder{
    padding-left: 5px;
  }


}
